<script>
import LoginForm from "@/components/molecules/login-form/ed-molecule-login-form.vue";
import PopUp from "@/components/organisms/pop-up/ed-organism-pop-up.vue";
import {onMounted, ref} from "vue";
import {useAppStore} from "@/store/appStore";

export default {
  name: "LoginBlock",
  components: {PopUp, LoginForm},
  setup(props, {emit}) {
    const showForm = ref(true);
    const appStore = useAppStore();
    const modalInterface = ref(null);
    function submitLoginRequest(email) {
      appStore.sendLoginEmail(email);
      showForm.value = false;
    }
    function reset() {
      showForm.value = true;
    }

    function setModalInterface(intf) {
      modalInterface.value = intf;
    }

    function emitInterface() {
      emit('interface', modalInterface.value);
    }

    onMounted(() => {
      emitInterface();
    });

    return {showForm, submitLoginRequest, reset, setModalInterface};
  }
}
</script>

<template>
  <pop-up @interface="setModalInterface">
    <div class="login-form d-md-grid gap-2 d-block">
      <login-form v-if="showForm" @submit="submitLoginRequest" />
      <div v-else>
        {{ $t('Login.We have sent you an email with a login link') }}.
        <br><br>
        {{ $t('Login.Did not receive an email') }}? <br><a class="btn-link" @click="reset">{{ $t('Login.Try again') }}</a>
      </div>
    </div>
  </pop-up>
</template>

<style scoped lang="scss">

</style>
