import DashboardView from "@/pages/private/dashboard-page.vue";
import HomeView from "@/pages/private/home-page.vue";
import ContactView from "@/pages/private/contact-page.vue";
import MyQuotesView from "@/pages/private/my-quotes-page.vue";
import ProfileView from "@/pages/private/profile-page.vue";
import QuoteDetailsView from "@/pages/private/quote-details-page.vue";
import PrivacyView from "@/pages/public/privacy-page.vue";
import TermsView from "@/pages/public/terms-page.vue";
import PrizeView from "@/pages/public/prize-page.vue";

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ "@/pages/public/login-page.vue"),
    meta: {
      public: true,
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
    meta: {
      public: true,
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
    meta: {
      public: true,
    }
  },
  {
    path: '/prize',
    name: 'prize',
    component: PrizeView,
    meta: {
      public: true,
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      public: false,
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {
          public: false,
        }
      },
      {
        path: 'my-quotes',
        name: 'myQuotes',
        component: MyQuotesView,
        meta: {
          public: false,
        },
      },
      {
        path: 'my-quotes/:id',
        name: 'quoteDetails',
        component: QuoteDetailsView,
        meta: {
          public: false,
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: ContactView,
        meta: {
          public: false,
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: ProfileView,
        meta: {
          public: false,
        }
      },
    ],
  },
  {
    path: '/qualify/:id',
    name: 'self-qualify',
    component: () => import(/* webpackChunkName: "self-qualify" */ "@/pages/private/self-qualify-page.vue"),
    meta: {
      public: false,
    }
  },
  {
    path: '/feedback/:id',
    name: 'user-feedback',
    component: () => import(/* webpackChunkName: "feedback" */ "@/pages/private/feedback-page.vue"),
    meta: {
      public: false,
    }
  }
];
